

function Store () {
    return (
        <div>
       
            <h1>Store coming soon...</h1>
        </div>
    )
}

export default Store;